export default {
    name: 'Stores',
    data: () => ({
        search: '',
        storetable: {
            itemsPerPage: 15
        },
        //{ text: 'Store Type', value: 'name', sortable: true },
        storecols: [
            { text: '', value: 'id', sortable: false },
            { text: 'Store Number', value: 'storenumber', sortable: true },
            { text: 'Store Name', value: 'storename', sortable: true },
            { text: 'Store City', value: 'storecity', sortable: true },
            { text: 'Edge', value: 'edge', sortable: true },
            { text: 'PE', value: 'pe', sortable: true },
            { text: '', value: 'taxable', sortable: false }
        ],
        confirmdelete: false,
        storetodelete: '',
        expanded: [],
        recsdisplayed: 0,
        editstore: false,
        storetoedit: {}
    }),
    mounted() {
        this.$store.dispatch('Stores/fetchStores', null, { root: true })
        this.$store.dispatch('Stores/fetchStoreTypes', null, { root: true })
        this.$store.dispatch('Stores/fetchProgramTypes', null, { root: true })
    },
    computed: {
        storerecs: { get() { return this.$store.getters['Stores/getStores'] } },
        edgecount: { get() { return this.$store.getters['Stores/getEdgeCount'] } },
        pecount: { get() { return this.$store.getters['Stores/getPeCount'] } },
        dmrecs: { get() { return this.$store.getters['Stores/getDms'] } },
        user: { get() { return this.$store.getters['Sgs/getUser'] } },
        storetypes: { get() { return this.$store.getters['Stores/getStoreTypes'] } },
        programtypes: { get() { return this.$store.getters['Stores/getProgramTypes'] } },
    },
    methods: {
        refresh() {
            this.$store.dispatch('Stores/fetchStores', null, { root: true })
        },
        getDownload() {
            this.$store.dispatch('Sgs/sendSheet', { op: 'storelist', recs: this.storerecs })
        },
        getTotalItems(e) {
            this.recsdisplayed = e.itemsLength
        },
        getdms(item) {
            if (this.expanded.length && this.expanded[0].storenumber === item.storenumber) {
                this.expanded = []
            } else {
                this.$store.dispatch('Stores/fetchDms', { store: item.storenumber }, { root: true })
                this.expanded = []
                this.expanded.push(item)
            }
        },
        asktoconfirm(whichstore) {
            this.confirmdelete = true
            this.storetodelete = whichstore
        },
        delstore(store) {
            this.confirmdelete = false

            let user = this.$store.getters['Sgs/getUser']

            this.$store.dispatch('Stores/deleteStore', { store: store, userid: user.uid }, { root: true })
                .then(() => {
                    this.refresh()
                })
                .catch((err) => {
                    console.log('DEL ERR')
                    console.log(err)
                })
        },
        showedit(store) {
            this.editstore = true

            this.storetoedit = store
        },
        savestore(store) {
            this.editstore = false

            let user = this.$store.getters['Sgs/getUser']

            this.$store.dispatch('Stores/saveStore', { store: store, userid: user.uid }, { root: true })
                .then(() => {
                    this.refresh()
                })
                .catch((err) => {
                    console.log('DEL ERR')
                    console.log(err)
                })
        },
        cancelsavestore() {
            this.editstore = false
            this.storetoedit = {}
            this.refresh()
        },
    }
}