<template>
    <v-container fluid>
        <!-- v-row class="text-center">
            <v-flex xs12>
                <h1>System Utilities</h1>
            </v-flex>
        </v-row -->
        <StoresForm />
    </v-container>
</template>

<script>
import StoresForm from '@/components/StoresForm.vue'

export default {
    name: 'Stores',
    components: {
        StoresForm,
    },
}
</script>